import 'primeicons/primeicons.css';
import '@/assets/fonts.css';
import '@/assets/index.css';

import App from './App.vue';
import { createApp } from 'vue';

import Tooltip from 'primevue/tooltip';
import PrimeVue from 'primevue/config';
import Lara from '@primevue/themes/lara';
import Aura from '@primevue/themes/aura';
import { definePreset } from '@primevue/themes';
import ToastService from 'primevue/toastservice';
import DialogService from 'primevue/dialogservice';
import ConfirmationService from 'primevue/confirmationservice';

const app = createApp(App);

// // noir
// const preset = definePreset(Lara, {
//     semantic: {
//         primary: {
//             50: '{zinc.50}',
//             100: '{zinc.100}',
//             200: '{zinc.200}',
//             300: '{zinc.300}',
//             400: '{zinc.400}',
//             500: '{zinc.500}',
//             600: '{zinc.600}',
//             700: '{zinc.700}',
//             800: '{zinc.800}',
//             900: '{zinc.900}',
//             950: '{zinc.950}',
//         },
//         colorScheme: {
//             light: {
//                 primary: {
//                     color: '{zinc.950}',
//                     inverseColor: '#ffffff',
//                     hoverColor: '{zinc.900}',
//                     activeColor: '{zinc.800}',
//                 },
//                 highlight: {
//                     background: '{zinc.950}',
//                     focusBackground: '{zinc.700}',
//                     color: '#ffffff',
//                     focusColor: '#ffffff',
//                 },
//             },
//             dark: {
//                 primary: {
//                     color: '{zinc.50}',
//                     inverseColor: '{zinc.950}',
//                     hoverColor: '{zinc.100}',
//                     activeColor: '{zinc.200}',
//                 },
//                 highlight: {
//                     background: 'rgba(250, 250, 250, .16)',
//                     focusBackground: 'rgba(250, 250, 250, .24)',
//                     color: 'rgba(255,255,255,.87)',
//                     focusColor: 'rgba(255,255,255,.87)',
//                 },
//             },
//         },
//     },
// });

const preset = definePreset(Aura, {
    semantic: {
        primary: {
            50: '{amber.50}',
            100: '{amber.100}',
            200: '{amber.200}',
            300: '{amber.300}',
            400: '{amber.400}',
            500: '{amber.500}',
            600: '{amber.600}',
            700: '{amber.700}',
            800: '{amber.800}',
            900: '{amber.900}',
            950: '{amber.950}',
        },
    },
});

app.use(PrimeVue, {
    theme: {
        preset,
        options: {
            darkModeSelector: '.prime-dark',
        },
    },
});

app.use(ToastService);
app.use(DialogService);
app.use(ConfirmationService);

app.directive('tooltip', Tooltip);

app.mount('#vue-core');
